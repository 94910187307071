import Ubication from '../components/Ubication'

const Contact = () => {
  return (
    <section className='location--page'>
      <Ubication />
    </section>
  )
}

export default Contact
